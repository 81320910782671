import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import image17 from "../../images/about/image17.jpg";

const Bishop = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Bishop Malayil Sabu Koshy Cherian" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">
                Bishop Malayil Sabu Koshy Cherian
              </h3>
              <p>
                Rt. Rev. Dr Malayil Sabu K. Cherian, a bishop with a
                missionary’s heart, who is well- known for his ecological
                visions and interest in farming, has been consecrated as the
                Bishop of the Madhya Kerala Diocese of the Church of South India
                (CSI) on the 18th January 2021.
              </p>
              <div className="about-image-wrapper about-image-wrapper_small">
                <img src={image17} className="w-100" alt="image17"></img>
              </div>
              <p>
                Bishop Malayil Sabu K. Cherian was ordained as a deacon of the
                CSI in 1988 and a presbyter in 1989. He started his ministry as
                a missionary in the Andhra Mission (currently Telangana Mission)
                where his wife Dr Mrs Jessy Sara Koshy joined him in the mission
                work as a missionary doctor. Bishop is blessed with two sons; Mr
                Sibu Cherian Koshy who is married to Ms Roopa Esther Philip and
                settled in Ireland and Dr Sam John Koshy who is currently
                pursuing his post-graduation in Chennai.
              </p>
              <p>
                During the secular education, Rev. Dr Sabu K. Cherian earned BSc
                in Mathematics, PGDCA, B.Ed, and MA in Sociology. Later he
                received his Bachelor of Divinity (BD) from UBS, Pune and Doctor
                of Ministry from New York Theological Seminary.
              </p>
              <p>
                He is known for his ecumenical convictions. He was the Member,
                Kerala Council of Churches, founding President, Ecumenical
                Fellowship in Kumplampoika, Vice President, Bible Society Kerala
                Auxiliary, Vice President, Ecumenical Fellowship Kottayam,
                President, Union Christian Fellowship in Mavelikara, Vice
                President, Ecumenical Clergy Fellowship Thiruvalla, Member,
                Christian Medical College Governing Council Vellore, Member,
                Nilackal Ecumenical Trust, Nilackal and President, Ecumenical
                Fellowship in Mooledom.
              </p>
              <p>
                While he was a Missionary Andhra Mission (1988 - 1996), he
                spearheaded medical, educational and church planting ministry in
                and around the Mogullapally and Chittiyala mandals in Warangal
                district. He acquired the ability to read, write and preach in
                the Telugu language. As a missionary, he supported the protest
                movements of the marginalized labourers for suitable daily
                wages. He conducted free night schools for the illiterate people
                in villages.
              </p>
              <p>
                Later he served St. Andrews Church Kumplampoika (1996 - 2000).
                During this tenure, he completed the construction of Parish
                Hall. He was posted as the Vicar of the newly formed Jubilee
                Church in New York (2000 - 2003). Being the Vicar of the CSI
                Ascension Church, Kanjikuzhy, Kottayam. (2003 - 2006), he
                constructed the second phase of the shopping complex and started
                Free Breakfast Programme for the needy sick people of the
                Kottayam Medical College Hospital through Sevananilayam. While
                he serves the St Paul's Church, Mooledom, Kottayam. (2006 -
                2011), he was instrumental in constructing the new Parsonage and
                renovated the church. At CSI Christ Church in Mavelikara
                (2011-2013), he started free meal programme for the needy sick
                people of the Taluk Hospital, Mavelikara and gave leadership for
                the ecumenical programmes. At St Mary's Church, Kuzhikkala (2013
                - 2014), Rev. Sabu constructed the second phase of the
                Parsonage. At the St. Thomas Church, Tholassery (2017- Present),
                he completed the construction of shopping complex phase, focused
                on the spiritual upliftment of the members and started daily
                online teleconference prayer fellowships soon after the country
                went into lockdown, utilized the barren land to start organic
                farming and promoted green homes (Parish received diocesan level
                green parish, green parsonage and green school awards for the
                last two consecutive years), helped the community kitchen by
                providing agricultural products cultivated from parish property
                during the pandemic period, and gave leadership for supporting
                the families affected by floods in surrounding areas.
              </p>
              <p>
                He was elected as the Treasurer of the Diocese of Madhya Kerala
                in 2014 for three years. Being the Treasurer, he introduced
                centralized salary system for all clergy and evangelists, took
                special care to protect and safeguard the Diocesan property,
                successfully managed diocesan Bi-centenary celebrations and 35th
                session of CSI Synod meeting during the demonetization period,
                renewed rental agreements of the shopping complex tenants, which
                was pending for decades, in line with the current market rates.
              </p>
              <p>
                The new Bishop hails from the Malayil family in Punnackadu.
                Bishop Malayil Sabu Koshy’s father (Late) M. K. Cherian was a
                retired teacher from CMS High School, Kuzhikkala, and mother
                (Late) Aleyamma Cherian was also a retired teacher from
                Government High School, Kozhencherry. The Bishop has five elder
                sisters and a younger brother.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Bishop;
